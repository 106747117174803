// @import "node_modules/bulma/sass/utilities/initial-variables";

// $black: rgb(3, 3, 3);
// $background: $black;
// $text: rgb(252, 252, 252);

// @import "node_modules/bulma/bulma";

// html, body {
//     background-color: $background;
// }

.has-padding-10 {
    padding: 10px;
}
.has-padding-top-20 {
    padding-top: 20px;
}

@charset "utf-8";

// Set your brand colors
$black: rgb(3, 3, 3);

// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
$grey-dark: #EFF0EB;
$title-color: white;
// $widescreen-enabled: false;
// $fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $black;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

.notification {
    background-color: #131313;
    padding: 10px 0;
}

@import "node_modules/bulma/sass/utilities/_all.sass";
@import "node_modules/bulma/sass/base/_all.sass";
@import "node_modules/bulma/sass/elements/button.sass";
@import "node_modules/bulma/sass/elements/container.sass";
@import "node_modules/bulma/sass/elements/title.sass";
@import "node_modules/bulma/sass/elements/image.sass";
@import "node_modules/bulma/sass/layout/hero.sass";
@import "node_modules/bulma/sass/components/media.sass";
@import "node_modules/bulma/sass/grid/columns.sass";

// .hero.has-pattern {
//     background-color: #030303;
//     background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23545454' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
// }

.box {
    display: table;
    > p {
        padding: 10px 20px;
        // border: 2px solid #63a1e6;
        // background-color: #0d7af3;
    //     background-color: #0d7af3;
    // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23545454' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
        // background-color: #0d7af3;
        // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%2363a1e6' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
        background-color: #62e858;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%234eba46' fill-opacity='0.65' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
        height: 88px;
        font-size: 1.2em;
        font-weight: 700;
        text-align: center;
        display: table-cell;
        vertical-align: middle;   
        color: black; 
    }
}

figure {
    margin: 0 auto 5px auto;
}

image.is-144x144 {
    width: 144px;
    height: 144px;
}

.person {
    img {
        max-height: 400px;
    }
}

footer {
    // background-color: #0d7af3;
    // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%231163bd' fill-opacity='0.24'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-color: #bd1010;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%236e1f1f' fill-opacity='0.24'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    .shadow-box {
        background-color: rgba(0, 0, 0, .3);
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 10px;
    }

}